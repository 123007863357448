import React from "react"

import Layout from "../components/ListLayout"
import SEO from "../components/seo"

/*
```js
try {    
  學習(something)     
} catch(e) {    
  錯誤中學習(e)     
} finally {    
  記下來()    
}
```
*/

const AboutPage = props => (
  <Layout>
    <SEO title="關於" pathname={props.location.pathname} />
    <br />
    <p>哈囉，我是Winsome</p>
    <p>我是一個住在高雄的軟體工程師。</p>
    <p>經營一個可愛的家庭。</p>
    <br />
    <h4>關於Winsome在學</h4>
    <br />
    <p>受到很多youtuber認真過生活的啟發</p>
    <p>想要用文字搭影音的方式紀錄當下的生活心得</p>
    <p>在艾賓豪斯的遺忘曲線下，日後要回憶起來，應該會比較快</p>
    <p>如果我的紀錄也剛好幫到忙，那我會很開心的</p>
    <br />
    <h4>聯絡我/給回饋</h4>
    <p>信箱: has.winsome@gmail.com</p>
    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/Winsome%E5%9C%A8%E5%AD%B8-110719310505969/?modal=admin_todo_tour"
      >
        Ｗinsome在學粉絲團
      </a>
    </p>
    <br />
    {/* <h4>生活重心</h4>
    <svg viewBox="0 0 300 150">
      <circle cx="80" cy="85" r="40" style={{ fill: "#d4edf4" }} />
      <text x="80" y="85" text-anchor="middle" dy=".3em">
        Code
      </text>
      <circle cx="180" cy="50" r="40" style={{ fill: "#e2f2d5" }} />
      <text x="180" y="50" text-anchor="middle" dy=".3em">
        家庭
      </text>
      <circle cx="140" cy="100" r="20" style={{ fill: "#f9fbba" }} />
      <text x="140" y="100" text-anchor="middle" dy=".3em">
        閱讀
      </text>
    </svg> */}
  </Layout>
)

export default AboutPage
